const mySwiper = new Swiper ('.swiper-container', {
  // Optional parameters

  // スライダーの方向を指定（'horizontal' or 'vertical'）
  direction: 'horizontal',

  // スライドのスピード（ミリ秒）
  speed: 600,

  // オートプレイ
  autoplay: {
    delay: 2000,
  },

  loop: true,
});
